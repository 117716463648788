/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  FWAD base Font size
*/
/*
 *  Use Defaults
*/
/*
 * Background Image
 */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * Mobile
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/**
*   Background gradient mixin
**/
/*
 * Opacity
 */
/*
 * Box Shadow
 */
/*
 * Box Shadow
 */
/*
 * upto Tablet
 */
/*
 * Clearfix
 */
/*
 * @ Fonts
 *
*/
/*
 * H1
*/
/* Russian */
/* Chinese */
/*
 * H2
*/
/* Russian */
/* Chinese */
/*
 * H3
*/
/* Russian */
/* Chinese */
/*
/*
 * H4
*/
/* Russian */
/* Chinese */
/*
 * H5
*/
/* Russian */
/* Chinese */
/*
 * H6
*/
/* Russian */
/* Chinese */
/*
 * body-1
*/
/*
 * body-copy-2
*/
/*
 * body-3
*/
/*
 * body-copy-4
*/
/*
 *  Base Container
*/
/*
 *  Generic Colors
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 * Base
 * @ Colors
 *
*/
/* Headings */ /*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Flexbox
 *
*/
.flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*
* Breakpoint
* @ Variables
*
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    to be applied on page parent level
*/
/*
    wrapper for each component
    to be used to give max width to your component
*/
.component {
  margin-bottom: 40px;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .component {
    margin-bottom: 60px;
  }
}
.component .w--content {
  margin: 0 auto;
  text-align: center;
  max-width: 93.3333333333%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .component .w--content {
    max-width: 96.875%;
  }
}
@media only screen and (min-width: 1024px) {
  .component .w--content {
    max-width: 96.09375%;
  }
}
@media only screen and (min-width: 1366px) {
  .component .w--content {
    max-width: 1296px;
  }
}

@font-face {
  font-family: "icon-font";
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * Line height font
 */
.tenant-ppad .c-tabs-with-placeholder .component .w--content {
  width: 100%;
  max-width: none;
}
.tenant-ppad .c-tabs-with-placeholder .component-title {
  text-align: center;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  margin: 30px auto 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 47.311827957%;
    max-width: 484.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 30px auto 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 97.9674796748%;
    max-width: 1269.6585365854px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    text-align: center;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 100%;
    max-width: 1296px;
    padding: 0;
    margin: auto;
    margin-top: 40px;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  border: solid 1px #d4d4d4;
  border-radius: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    border: 0;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu:before {
  border-width: 1px;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown div[class=tab-menu-dropdown-container] {
  border-radius: 0;
  border: 1px solid #d4d4d4;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  letter-spacing: 0.22px;
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  left: 18px;
  color: #002677;
  top: 15px;
  /* Small devices (tablets, 768px and up) */
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  right: 18px;
  left: auto;
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-head-length {
  display: none;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  right: 19px;
  top: 18px;
  width: 8px;
  height: 8px;
  border-color: #002677;
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  left: 19px;
  right: auto;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
  font-weight: 800;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6.is-active {
  font-family: "PlayFairRegular", "Open Sans", Arial, sans-serif;
  font-weight: 800;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: #000;
    letter-spacing: 0;
    font-weight: 700;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-family: "JannaBold", "Open Sans", Arial, sans-serif;
    letter-spacing: 0;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .heading-6.is-active {
    font-weight: 800;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 88.9784946237%;
    max-width: 911.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  opacity: 1;
  padding: 15px 20px;
  line-height: normal;
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  letter-spacing: 0.22px;
  font-weight: normal;
  color: #002677;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 1.5rem;
    letter-spacing: 0.25px;
    font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-family: "JannaBold", "Open Sans", Arial, sans-serif;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
  border-color: #fdf7ee;
  background-color: #fdf7ee;
  color: #ad841f;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    background-color: transparent;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active span {
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active span {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    max-width: none;
  }
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    padding: 15px;
    border-bottom: 2px solid #e8e8e8;
  }
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    color: #002677;
    border-bottom: 2px solid #ad841f;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    padding: 20px;
  }
}
.tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu::before {
  right: 15px;
  border-radius: 4px;
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu::before {
  left: 15px;
  right: auto;
}
.tenant-ppad .c-tabs-with-placeholder .tabs-menu {
  width: 100%;
  padding: 0;
}
.tenant-ppad .c-tabs-with-placeholder .tabs-menu--links {
  text-align: left;
  opacity: 1;
  border-bottom: 1px solid #e8e8e8;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links {
  text-align: right;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links:hover {
    opacity: 0.8;
  }
}
.tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
    text-align: center;
    display: block;
    font-weight: 600;
  }
}
.tenant-ppad .c-tabs-with-placeholder .tabs-menu--links.is-active {
  border-bottom: 1px solid #fdf7ee;
}
.tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  letter-spacing: 0.25px;
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  color: #002677;
  opacity: 1;
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-menu--links span {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
}
.tenant-ppad .c-tabs-with-placeholder .tabs-panel-container {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container {
    width: 97.9674796748%;
    max-width: 1269.6585365854px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container {
    width: 97.9674796748%;
    max-width: 1269.6585365854px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    padding: 0;
  }
}
.tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .component .w--content {
  margin: 0 auto;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .component .w--content {
    padding: 0;
  }
}
.tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--title {
    margin-left: 0;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--title {
    margin-right: 0;
    margin-left: auto;
    margin-left: auto;
  }
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--bodycopy {
    margin-left: auto;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--bodycopy {
    margin-right: auto;
    margin-left: auto;
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--title {
    width: 22.9674796748%;
    max-width: 297.6585365854px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin-left: 0;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--title {
    margin-right: 0;
    margin-left: auto;
    margin-left: auto;
  }
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--bodycopy {
    width: 72.9674796748%;
    max-width: 945.6585365854px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin-left: auto;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--bodycopy {
    margin-right: auto;
    margin-left: auto;
    margin-left: 0;
  }
}
.tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content {
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--title {
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: #000;
  letter-spacing: 0;
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-weight: 600;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--title {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--title {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--title {
    margin-left: 0;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy {
    margin-left: auto;
    margin-right: 0;
  }
}
.tenant-ppad .c-tabs-with-placeholder.component:not(.v-tabs-hotel-booking-widget) .tabs-panel-container {
  margin: 0 auto;
  padding: 0;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-tabs-with-placeholder.component:not(.v-tabs-hotel-booking-widget) .tabs-panel-container {
    width: 100%;
    max-width: 1296px;
  }
}
.tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: #000;
  letter-spacing: 0;
  color: #000;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
    border-top: none;
  }
}
.tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--bodycopy:first-of-type {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--bodycopy:first-of-type {
    border-top: none;
  }
}
.tenant-ppad .c-tabs-with-placeholder.v-tabwithplaceholder-view .tabs-menu {
  width: 81%;
  overflow-x: scroll;
  white-space: nowrap;
  justify-content: space-between;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder.v-tabwithplaceholder-view .tabs-menu--links {
  width: auto;
  text-align: center;
  padding: 15px;
}
.tenant-ppad .c-tabs-with-placeholder.v-tabwithplaceholder-view .tabs-menu--links.is-active {
  border-bottom: 2px solid #ad841f;
}
@media (min-width: 768px) {
  .tenant-ppad .c-tabs-with-placeholder.v-tabwithplaceholder-view .tabs-menu {
    width: 92%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder.v-tabwithplaceholder-view .tabs-menu {
    width: 98%;
    overflow: hidden;
  }
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
  letter-spacing: 1px;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 0;
  color: #002677;
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
    color: #333;
  }
  .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:after {
    background: #f6f6f6;
    box-shadow: 2px 0px 4px -1px rgba(0, 0, 0, 0.33);
  }
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
  background: #ad841f;
  color: #fff;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) span,
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) a {
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
    background: transparent;
    color: #333;
  }
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(even):after {
  background: #fdf7ee;
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(2) {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(2) {
    z-index: 6;
  }
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
  background: #ad841f;
  color: #fff;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active span,
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active a {
  color: #fff;
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active:after {
  background: #ad841f;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
    background: transparent;
    z-index: 7;
  }
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-panel-container .tabs-panel.is-active .close-tab svg {
  display: none;
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-panel-container .tabs-panel.is-active .close-tab:before, .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-panel-container .tabs-panel.is-active .close-tab:after {
  position: absolute;
  right: 5px;
  content: " ";
  height: 14px;
  width: 1px;
  background-color: #000;
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-panel-container .tabs-panel.is-active .close-tab:before {
  transform: rotate(45deg);
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-panel-container .tabs-panel.is-active .close-tab:after {
  transform: rotate(-45deg);
}
.tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .react-datepicker__day:not(.react-datepicker__day--disabled):hover:after {
  background: #ad841f;
}
.tenant-ppad .c-tabs-with-placeholder .cmp-preference-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 0;
}
.tenant-ppad .c-tabs-with-placeholder .cmp-preference-form .communication-preference .checkbox-label {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.tenant-ppad .c-tabs-with-placeholder .c-form.c-my-preferences .fieldset-inner .cmp-select-all-checkbox .form-element.form-checkbox .checkbox-label {
  flex-direction: row-reverse;
  margin-bottom: 0;
  justify-content: flex-end;
}
.tenant-ppad .c-tabs-with-placeholder .c-form.c-my-preferences .fieldset-inner .form-element.form-checkbox .checkbox-label {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 0;
}

.lang-ar .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu--links span,
.lang-ar .tenant-ppad .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget .tabs-menu--links a {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: 800;
}

.tenant-ppad .c-tabs-with-placeholder {
  max-width: 1296px;
  margin: 0 auto;
}
.tenant-ppad .c-tabs-with-placeholder .component.c-collapsible-content .w--content {
  width: 100%;
  max-width: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy {
    margin-left: auto;
    margin-right: auto;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy {
    margin-right: auto;
    margin-left: auto;
    margin-left: 0;
  }
  .lang-ar .tenant-ppad .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy {
    margin-left: auto;
    margin-right: auto;
    margin-right: 0;
  }
}